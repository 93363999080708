<template>
  <section id="Latest_work" class="lg:w-3/4 mx-auto my-20">
    <div
      class="
        grid grid-cols-1
        sm:grid-cols-2 sm:gap-x-4
        md:grid-cols-3 md:gap-x-3
        lg:gap-20
        w-full
      "
    >
      <router-link
        :to="{ name: 'producer', params: { id: producer.producerId } }"
        class="group"
        :key="index"
        v-for="(producer, index) in producers"
      >
        <div
          class="
            border border-grey-100
            p-4
            block
            h-72
            sm:h-72
            md:h-56
            lg:h-72
            xl:h-80
            2xl:h-85
            hover:border-grey-300
            transform
            duration-700
          "
        >
          <img :src="producer.producerImage" alt="" class="w-full h-full" />
        </div>

        <span class="mt-8 text-sm text-grey-500 font-normal block text-center">
          {{ producer.producerName }}
        </span>
        <!-- <span class="mt-2 block text-center">
          <span
            class="text-lg text-grey-900 font-bold inline-block hover:underline"
          >
            {{ producer.producerName }}</span
          >
        </span> -->
      </router-link>
    </div>
  </section>
</template>

<script>
export default {
  components: {},
  created() {
    window.scroll(0, 0);
    this.GetProducers();
  },
  data() {
    return {
      producers: [],
    };
  },
  methods: {
    GetProducers() {
      this.$http.producersService
        .GetProducers()
        .then((res) => {
          this.producers = res.data.result;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
</style>